@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/gradients";
@import "mixins/mixins";
@import '~ngx-toastr/toastr';

$primary: #E6772E;
html, body, app-root { margin: 0; padding: 0; height: 100%; }

$theme-colors: (
    dark: #4D4D4D,
    b-blue: #7B87FF,
    b-green: #00B38A
);

// Statuses/pills - unused
$status-green:      #5DD879 !default;
$status-darkgreen:  #2DBC4E !default;
$status-red:        #E56B77 !default;
$status-darkred:    #D44B59 !default;
$status-blue:       #59A9FF !default;
$status-orange:     #FD9A47 !default;
$status-yellow:     #FFC107 !default;
$status-grey:       #CED4DA !default;
$status-purple:     #BA55D3 !default;

$accent: #007bff;
$secondary: #ccc;
$table-cell-padding: .5rem;
$font-size-base: 0.85rem;

$input-border-radius:         0;
$input-border-radius-lg:      0;
$input-border-radius-sm:      0;
$btn-border-radius:           0;
$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;
$dropdown-border-radius:      0;
$card-border-radius:          0;


$btn-padding-y: 0.75rem;
$btn-padding-x: 1.25rem;
$btn-font-size: 1rem;
$btn-line-height: 1;

//Added kendo variable overwrites into separate file
@import "kendoVariables";

@import "~@progress/kendo-theme-bootstrap/scss/button";
@import "~@progress/kendo-theme-bootstrap/scss/input";
@import "~@progress/kendo-theme-bootstrap/scss/numerictextbox";
@import "~@progress/kendo-theme-bootstrap/scss/combobox";
@import "~@progress/kendo-theme-bootstrap/scss/multiselect";
@import "~@progress/kendo-theme-bootstrap/scss/datetime";
@import "~@progress/kendo-theme-bootstrap/scss/upload";
@import "~@progress/kendo-theme-bootstrap/scss/splitter";
@import "~@progress/kendo-theme-bootstrap/scss/grid";
@import "~@progress/kendo-theme-bootstrap/scss/tabstrip";
@import "~@progress/kendo-theme-bootstrap/scss/dialog";
@import "~@progress/kendo-theme-bootstrap/scss/editor";
@import "~bootstrap/scss/bootstrap";


.k-autocomplete, .k-combobox, .k-dateinput, .k-datepicker, .k-datetimepicker,
.k-dropdown, .k-multiselect, .k-numerictextbox, .k-maskedtextbox, .k-timepicker,
.k-dropdowntree {
    font-size: $font-size-base;
}


.form-control.k-widget {
    border: 0;
}

.form-control-sm {
    .k-multiselect-wrap .k-button {
        min-height: calc( 1.2em + 0.25rem + 2px + 2px );
        padding: calc( 0.1rem + 1px ) 0.275rem;
    }

    .k-autocomplete .k-clear-value, .k-dropdown-wrap .k-clear-value, .k-multiselect-wrap .k-clear-value {
        height: 1.2rem;
        line-height: 1.6rem;
    }

    .k-multiselect-wrap {
        height: 57px;
        border: 0;
        border-bottom: 1px solid #C1C1C1;
        transition: all 0.3s;

        &:focus {
            border-bottom: 2px solid $brand-primary;
            background: #DCDCDC
        }

        li.k-button {
            min-height: 1rem;
            line-height: 1rem;
            margin: 0.19rem 0 0 0.19rem;
            padding: 0.19rem 0.38rem 0.19rem 0.38rem;
            border-radius: 0.19rem;

            @include theme() {
                background-color: theme-get('button-bg');
                border-color: theme-get('button-bg');
                color: theme-get('text-color');
            }

            .k-icon:hover {
                @include theme() {
                    color: theme-get('active-icon-color');
                }
            }
        }

        .k-select {
            width: 1rem;
        }
    }
}

.k-datetimepicker .k-link {
    padding: 0;
}

.k-combobox .k-clear-value {
    margin-top: 6px;
}

.k-list .k-item:focus:hover, .k-list .k-item:focus.k-state-hover, .k-list .k-item.k-state-focused:hover, .k-list .k-item.k-state-focused.k-state-hover, .k-list-optionlabel:focus:hover, .k-list-optionlabel:focus.k-state-hover, .k-list-optionlabel.k-state-focused:hover, .k-list-optionlabel.k-state-focused.k-state-hover {
    @include theme() {
        box-shadow: inset 0 0 0 1px rgba(theme-get('shadow'), 0.1);
    }
}
.k-list .k-item:focus, .k-list .k-item.k-state-focused, .k-list-optionlabel:focus, .k-list-optionlabel.k-state-focused {
    @include theme() {
        box-shadow: inset 0 0 0 1px rgba(theme-get('shadow'), 0.1);
    }
}

@import "~spinkit/spinkit.css";

table.table-sm {
    font-size: 13px;

    th {
        font-weight: normal;
    }
}
.k-grid-content-sticky {
    @include theme() {
        background: theme-get('menu-bg') !important;
        border-color: theme-get('menu-color') !important;
    }
}
.k-grid th, .k-grid td {
    padding: 0.2rem 0.5rem;
}

.pointer {
    cursor: pointer;
}

body {
    padding-top: 56px;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    background-color: #edecec;
}

label {
    font-size: $font-size-sm !important;
    line-height: 1.2 !important;
}

.btn-xs, .btn-group-xs > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.675rem;
    border-radius: $border-radius-sm;
}

button {
    fa-icon {
        @include theme() {
            color: theme-get('icon-color');
        }
    }

    // Filter search and clear buttons & grid edit and delete buttons
    &.btn.btn-primary, &.btn.btn-danger, &.btn.btn-danger, &.btn.btn-info {
        fa-icon {
            color: #fff !important;
        }
    }

    &.confirmButton {
        background-color: #e6772e !important;
        color: white !important;
    }

    &.close {
        outline: none;
        @include theme() {
            color: theme-get('icon-color');
        }
    }
}

.port-filter {
    margin: 0;

    .k-button {
        border-radius: 4px !important;
        border: 0;
        background-color: $brand-primary;
        font-size: 0.9rem;

        @include theme() {
            color: theme-get('text-color');
        }

        &:not(:first-child):not(:last-child) {
            margin-right: 10px;
        }

        &:hover:not(.selected) {
            background-color: darken($brand-primary, 10%) !important;
        }
    }

    .selected {
        background-color: darken($accent, 12.5%) !important;
        color: #fff;
    }
}

// .input-group-prepend ~ input { width: 80% !important }

.accordion > .card {
    overflow: visible;
}

/*
 * Loading animation
 */
.sk-rotating-plane {
	background-color: #FF934C !important;
	width: 80px!important;
	height: 80px!important;

    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    } 50% {
          transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
      } 100% {
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        }
}

/*
 * Scrollbars
 */
 ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    @include theme() {
        background: theme-get('input-bg-disabled');
    }
}

::-webkit-scrollbar-thumb {
    /*background: #007bff;*/
    background: #E6772F;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.card-header-tabs > .nav-item {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;   /* Firefox all */
    -ms-user-select: none;  /* IE 10+ */
    user-select: none;  /* Likely future */
}

.btn-link {
    border: 0;
    background: transparent;

    &:hover, &:focus {
        background: transparent !important;
        border: 0 !important;
        outline: 0 !important;
    }
}

.navbar {
    @include theme() {
        background-color: theme-get('main-bg');
        color: theme-get('input-color');
        border-color: theme-get('border-color');
    }

    span.toggle-menu {
        color: #fff;
        cursor: pointer;
        font-size: 1.5rem;
        opacity: .7;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;

        @include theme() {
            color: theme-get('input-color');
        }

        &:hover {
            opacity: 1;
        }
    }
}

input.form-control,input.form-control:focus {
    box-shadow: none !important;
   -webkit-box-shadow: none !important;
   -moz-box-shadow: none !important;
    transition: none;
   -moz-transition: none !important;
   -webkit-transition: none !important;
}

input.ng-dirty.ng-invalid {
    border-color: #cc0000 !important;
}

textarea {
    line-height: 100% !important;
}

.xmlText > div > textarea {
    height: 530px !important;
    padding-top: 15px !important;
}

.full-screen-modal > .modal-dialog, .full-screen-modal > .modal-dialog > .modal-content {
    max-width: 90vw;
}
// Due to text overflow
.form-group {
    .label-bg {
        @include theme() {
            background-color: theme-get('input-bg') !important;
        }
    }

    .disabled-bg {
        @include theme() {
            background-color: theme-get('input-bg-disabled') !important;
        }
    }
}

// input[type="text"],
// input[type="password"],
// input[type="number"],
// select {
//     // min-height: 42px;
// }

a {
    cursor: pointer;
}

.error-tooltip {
    .tooltip-inner {
        background-color: #e22e40 !important;
        color: #fff;
    }

    .tooltip-arrow {
        color: red !important;
    }

    .arrow::after .arrow::before {
        border: solid transparent;
        bottom: 100%;
        top: 50%;
        content: "";
        position: absolute;
    }

    .arrow::before {
        border-color: rgba(194, 225, 245, 0);
        border-right-color: #e22e40 !important;
        border-width: 7px;
        margin-top: -1px;
    }

    &.arrow-bottom .arrow::before {
        transform: rotate(-90deg);
    }
}

/**
 * Progress bar animations
 */
 @keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}


// Content Header
.content-header {
  position: relative;

  // Header Text
  & > h1 {
    margin: 0;
    font-size: 24px;

    & > small {
      font-size: 15px;
      display: inline-block;
      padding-left: 4px;
      font-weight: 300;
    }
  }

  & > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    @include border-radius(2px);
    > li > a, > li > span {
      color: #444;
      text-decoration: none;
      display: inline-block;
      > .fa, > .glyphicon, > .ion {
        margin-right: 5px;
      }
    }
    & > li + li:before {
      content: none;
    }
  }
}


.content-header>.breadcrumb{
    float: none;
    padding: 5px 0;
    position: unset;
}


.app-pager-page-sizes.k-dropdown .k-state-focused {
    box-shadow: none !important;
}

.k-dropdown .k-dropdown-wrap {
    height: 42px;
    text-align: center;
}

kendo-grid {
    .k-checkbox-label {
        padding: 20px;
        margin: -9px -9px;
    }
    .k-checkbox-label::after, .k-checkbox-label::before {
        margin-top: 10px;
        margin-left: 10px;
    }
}

kendo-splitter {
    @include theme() {
        background-color: theme-get('main-bg') !important;
    }
    border: 0 !important;
}


.add-extra > kendo-grid > div > table > thead {
    display: none;
}

.fixed-background {
  background: url("/img/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

th.k-header {
    background-color: #4D4D4D;
    color: #fff;
}
tr.k-detail-row > td.k-hierarchy-cell {
    border-left: 3px solid #7fbdff !important;
}

.k-datetime-container .k-datetime-wrap {
    width: 27em !important;
}
kendo-numerictextbox, kendo-combobox, kendo-datepicker, kendo-datetimepicker, kendo-multiselect {
    &.is-read-only {
        .k-input, .k-multiselect-wrap {
            @include theme() {
                background-color: theme-get('input-bg-disabled');
            }
        }
    }
}

.tab {
    @include theme() {
        color: theme-get('text-color');
        border-color: theme-get('border-color') !important;
    }
}

.k-tabstrip .k-tabstrip-items {
    display: block;

    @include theme() {
        border-bottom: 1px solid theme-get('border-color');
    }
}

.k-tabstrip-items .k-item {
    display: inline-block;
}

.overflow-unset {
    overflow: unset !important;
}

/* Infobox ===================================== */
.info-box {
    height: 80px;
    display: flex;
    cursor: default;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    @include theme() {
        box-shadow: 0 2px 10px rgba(theme-get('shadow'), 0.2);
    }

    .icon {
        display: inline-block;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.12);
        width: 80px;

        fa-icon {
            color: #fff;
            font-size: 50px;
            line-height: 80px;
        }

        .chart {

            &.chart-bar {
                height: 100%;
                line-height: 100px;

                canvas {
                    vertical-align: baseline !important;
                }
            }
        }
    }
}
.info-box-md {
    height: 60px;
    margin-bottom: 10px;

    .icon {

        fa-icon {
            color: #fff;
            font-size: 40px;
            line-height: 60px;
        }
    }
}





.info-box .icon .chart.chart-pie {
    height: 100%;
    line-height: 123px;
}
.info-box .icon .chart.chart-pie canvas {
    vertical-align: baseline !important;
}
.info-box .icon .chart.chart-line {
    height: 100%;
    line-height: 115px;
}
.info-box .icon .chart.chart-line canvas {
    vertical-align: baseline !important;
}
.info-box .content {
    display: inline-block;
    padding: 7px 10px;
}
.info-box .content .text {
    font-size: 13px;
    margin-top: 11px;
    color: #555;
}
.info-box .content .number {
    font-weight: normal;
    font-size: 26px;
    margin-top: -4px;
    color: #555;
}

.info-box.hover-zoom-effect .icon {
    overflow: hidden;
}
.info-box.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.info-box.hover-zoom-effect:hover .icon i {
    opacity: 0.4;
    -moz-transform: rotate(-32deg) scale(1.4);
    -ms-transform: rotate(-32deg) scale(1.4);
    -o-transform: rotate(-32deg) scale(1.4);
    -webkit-transform: rotate(-32deg) scale(1.4);
    transform: rotate(-32deg) scale(1.4);
}

.info-box.hover-expand-effect:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: ".";
    position: absolute;
    left: 80px;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    -moz-transition: all 0.95s;
    -o-transition: all 0.95s;
    -webkit-transition: all 0.95s;
    transition: all 0.95s;
}

.info-box.hover-expand-effect:hover:after {
    width: 100%;
}


//Kendo Overwrites
@import "kendoOverWrites";

.toggle-menu {
    svg {
         path {
             fill: $body-color;
         }
    }
}

.accordion {

    & > .card {
        border: 0;

        .card-body {
            border: 1px solid transparent;
            border-radius: 0 0 4px 4px;

            @include theme() {
                border-color: theme-get('border-color') !important;
            }
        }

        & > .card-header {
            padding: 12px 20px 12px 20px;

            .accordion-inner {
                padding: 0.75rem 1.25rem;
                background: #4D4D4D;
                border-radius: 4px;

                h5 {
                    font-size: 18px;
                    font-weight: 400;
                    margin: 0;
                }

                &.active {
                    border-radius: 4px 4px 0 0;
                }
            }
        }
    }
}

.simple-container {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 10px;
    min-height: 56px;

    @include theme() {
        color: theme-get('text-color');
        border-color: theme-get('border-color');
    }

    strong {
        text-transform: none;
    }

    a {
        &.incognito-link {
            color: inherit;
            text-decoration: none;
        }
    }

    &.cramped {
        padding: 0;
    }

    &.lite {
        min-height: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
    }

    &.accent {
        padding: 10px;

        @include theme() {
            background-color: theme-get('table-bg-color');
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            font-size: 16px;
            font-weight: 400;

            a:hover {
                @include theme() {
                    color: theme-get('active-icon-color');
                }
            }
        }
    }

    &.inactive {
        @include theme() {
            background-color: theme-get('input-bg-disabled');
            border-color: theme-get('input-bg-disabled');
            color: theme-get('input-color');
        }

        .orangify, .bluify {
            @include theme() {
                color: theme-get('input-color');
            }
        }
    }

    .orangify {
        color: $brand-primary;
    }

    .bluify {
        color: $blue;
    }

    .purplify {
        color: #8f3571;
    }

    .redify {
        color: red;
    }

    .capitalize {
        text-transform: uppercase;
    }

    .badge {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
        padding: 3px;
        border: 1px solid;
        border-radius: 4px;

        cursor: default;
        vertical-align: middle;
        font-size: 22px;
        font-weight: normal;

        @include theme() {
            border-color: theme-get('border-color');
            background-color: theme-get('main-bg');
        }

        > span:first-child {
            margin-left: -1px;
            margin-right: 2px;
        }
    }

    .video-grid {
        margin-bottom: 10px;

        h6 {
            height: 16px;
            line-height: 16px;
            padding-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 0;
        }

        .row:not(:first-child) {
            margin-top: 40px;
        }

        .row > .col {
            margin-top: 10px;
            width: 33%;
        }
    }
}

.box-shadow {
    @include theme() {
        box-shadow: 3px 3px 4px rgba(theme-get('shadow'), 0.2);
    }

    &.strong {
        @include theme() {
            box-shadow: 3px 3px 4px rgba(theme-get('shadow'), 0.4);
        }
    }

    &.tight {
        @include theme() {
            box-shadow: 2px 2px 2px rgba(theme-get('shadow'), 0.2);
        }
    }
}

// Documents & FAQ
.nav-tabs.simple {
    padding: 0;

    @include theme() {
        border-bottom: 1px solid theme-get('border-color');
    }

    .nav-item {
        .nav-link {
            @include theme() {
                background-color: theme-get('tab-default');
                color: theme-get('tab-default-color');
                border: 1px solid theme-get('border-color');
                border-bottom: 3px solid theme-get('border-color');
            }

            &:hover, &.active {
                @include theme() {
                    background-color: theme-get('tab-active');
                    color: theme-get('tab-active-color');
                    border-color: theme-get('active-icon-color');
                    border-bottom: 3px solid theme-get('active-icon-color');
                }
            }
        }
    }
}

.nav-item  {
    h5 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        border-radius: 4px;

        @include theme() {
            color: theme-get('text-color') !important;
        }
    }
}

.action-bar {
    @include theme() {
        background-color: theme-get('action-bg');
        color: theme-get('action-color');
    }

    .btn {
        @include theme() {
            color: theme-get('action-color') !important;
        }

        &:hover {
            color: $brand-primary !important;
        }
    }
}

h1, h2, h3, h4, h6 {
    @include theme() {
        color: theme-get('headings-color') !important;
    }
}

h5 {
    @include theme() {
        color: theme-get('headings-color');
    }
}

.k-chart-surface {
    height: 180px;
}

.actual-faq-list {
    .category-parent {
        background-color: #e6772f;
        color: white;
        padding: 3px;
        font-weight: bold;

    }
    .category {
        font-weight: bold;
    }

    @include theme() {
        color: theme-get('text-color');
    }
}

.actual-document-folders, .actual-faq-categories {
    &.folder-container, &.category-container {
        padding: 12px;
    }

    @include theme() {
        color: theme-get('text-color');
    }

    .folder-title, .category-title {
        padding: 5px 0 5px 10px;

        @include theme() {
            background-color: theme-get('header-background');
        }
    }

    .folder-items-container, .category-items-container {
        height: 600px;
        padding: 10px;
    }

    .folder-items-container, .folder-container, .category-items-container, .category-container {
        @include theme() {
            background-color: theme-get('table-bg-color');
        }
    }

    .folderColor, .categoryColor {
        @include theme() {
            color: theme-get('icon-color');
        }
    }

    a {
        color: inherit !important;
    }

    .active-folder, .active-category {
        color: #e6772f !important;
    }
}

.actual-document-edit, .actual-folder-edit, .actual-faq-edit, .actual-faq-category-edit {
    thead {
        @include theme() {
            > tr  {
                background-color: theme-get('header-background');
                line-height: 20px;

                > th {
                    border: 0;
                }
            }
        }
    }

    tbody {
        > tr:first-child td {
            border: 0;
        }

        > tr:not(:first-child) td {
            @include theme() {
                border-top: 1px solid theme-get('text-color');
            }
        }
    }
}

.actual-document-list {
    .folder-parent {
        background-color: #e6772f;
        color: white;
        padding: 3px;
        font-weight: bold;

    }
    .folder {
        font-weight: bold;
    }

    @include theme() {
        color: theme-get('text-color');
    }
}

.actual-table {
    a {
        color: inherit !important;

        &:hover {
            color: #007bff !important;
        }
    }
}

.table-bordered {
    tbody > tr > td, thead > tr > th {
        @include theme {
            border: 1px solid theme-get('border-color');
        }
    }
}

@media print {
    app-root > app-print-layout {
        display: block !important;
    }
    app-root > app-sidebar, app-root > main > kendo-splitter, #toast-container {
        display: none !important;
    }
}

kendo-multiselect .k-multiselect-wrap {
    overflow: hidden;
}

.createVtsEventModal > .modal-dialog {
    max-width: calc(100vw - 400px);
}
